const GA_EVENT_CATEGORY = "quote-ninja"

export function RecordAnalyticsEvent(eventType, eventLabel, eventData) {
  const ga = window.ga
  const dataLayer = window.dataLayer

  if (ga instanceof Function) {
    ga("send", "event", GA_EVENT_CATEGORY, eventType, eventLabel, eventData)
  }

  if (dataLayer && dataLayer.push instanceof Function) {
    dataLayer.push({
      type: "event",
      event: eventType,
      category: GA_EVENT_CATEGORY,
      label: eventLabel,
      data: eventData || null,
    })
  }
}

export function OpenIntercom() {
  if (window.Intercom) {
    window.Intercom("showNewMessage")
  }
}
