import React from "react"

import * as styles from "./index.module.scss"

const Modal = ({ children }) => {
  return (
    <div
      style={{ backgroundColor: "rgba(0, 0, 0, 0.3)" }}
      className={`${styles.modal} fixed top-0 left-0 right-0 bottom-0`}
    >
      <div
        className={`absolute top-0 left-0 right-0 bottom-0 ${styles.modalContent}`}
      >
        {children}
      </div>
    </div>
  )
}

export default Modal
