import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Link } from "gatsby"

import RevelutionizeCTA from "../revolutionizeCTA"
import FooterBG from "../../assets/images/footer-background.svg"
// import { FooterPhone, FooterChat, FooterDB } from "../icons"

import * as styles from "./index.module.scss"
import { OpenIntercom } from "../utils"

const footerNav = {
  navLinks: [
    { name: "Quotes", link: "/quotes" },
    { name: "Pricing", link: "/pricing" },
    { name: "Partners", link: "/partners" },
    { name: "About Us", link: "/about" },
    { name: "Contact", link: "/contact" },
  ],
}

const Footer = () => {
  const { footer } = useStaticQuery(graphql`
    query footerQuery {
      footer: contentfulFooter {
        heading1
        heading2
        heading3
        callTitle
        callLink
        callIcon {
          title
          file {
            url
          }
        }
        supportTitle
        supportLink
        supportIcon {
          title
          file {
            url
          }
        }
        chatTitle
        chatIcon {
          title
          file {
            url
          }
        }
        copyrightTitle
        marketerTitle
        marketerLink
        resources {
          id
          title
          externalLink
        }
      }
    }
  `)
  const { navLinks } = footerNav
  const {
    heading1,
    heading2,
    heading3,
    supportTitle,
    supportLink,
    supportIcon,
    chatTitle,
    chatIcon,
    copyrightTitle,
    marketerTitle,
    marketerLink,
    resources,
  } = footer
  const sectionOne = [
    { title: supportTitle, link: supportLink, icon: supportIcon },
    { title: chatTitle, icon: chatIcon },
  ]
  const year = new Date().getFullYear()

  return (
    <footer
      style={{
        backgroundImage: `url(${FooterBG})`,
      }}
      className={`${styles.footerContainer}`}
    >
      <RevelutionizeCTA />
      <div className="max-w-5xl px-8 pt-6 pb-4 m-auto lg:px-8 xl:px-0 md:pt-0 md:pb-8">
        <div className="flex flex-row flex-wrap justify-between py-5 md:flex-nowrap md:py-8">
          <div className="w-full md:w-1/3">
            <h4 className="text-white">
              {heading1 || "Questions or Need Help?"}
            </h4>
            <div className="mt-8">
              {sectionOne.map((item, index) => {
                const { link, title, icon } = item
                return link ? (
                  <a
                    key={index}
                    href={index === 2 ? `tel:${link}` : link}
                    target={index === 0 ? "_target" : undefined}
                    rel="noreferrer"
                    className={`${styles.footerButton}`}
                  >
                    <div className={`${styles.icon}`}>
                      <img src={icon.file.url} title={icon.title} alt="" />
                    </div>
                    <div className={`${styles.text}`}>{title}</div>
                  </a>
                ) : (
                  <button
                    key={index}
                    rel="noreferrer"
                    className={`${styles.footerButton}`}
                    onClick={OpenIntercom}
                  >
                    <div className={`${styles.icon}`}>
                      <img src={icon.file.url} title={icon.title} alt="" />
                    </div>
                    <div className={`${styles.text}`}>{title}</div>
                  </button>
                )
              })}
            </div>
          </div>
          <div className="w-full py-6 md:w-1/6 md:py-0 md:mx-6 xl:mx-0">
            <h4 className="font-semibold text-white">{heading2}</h4>
            <div className="flex flex-row flex-wrap mt-3 md:flex-nowrap md:flex-col md:mt-6">
              {navLinks.map((item, index) => (
                <Link
                  key={index}
                  to={item.link}
                  className={`w-1/2 lg:w-full mb-2 md:my-2 ${styles.hoverStyle}`}
                >
                  {item.name}
                </Link>
              ))}
            </div>
          </div>
          <div className="w-full md:w-1/2">
            <h4 className="font-semibold text-white">{heading3}</h4>
            <div className="grid grid-cols-2 mt-3 gap-x-4 lg:gap-x-12 md:mt-6">
              {resources.map(item => {
                const { id, title, externalLink } = item
                return (
                  <a
                    key={id}
                    href={externalLink}
                    rel="noreferrer"
                    target="_blank"
                    className={`mb-2 md:my-2 ${styles.hoverStyle}`}
                  >
                    {title}
                  </a>
                )
              })}
            </div>
          </div>
        </div>
        <div className="flex flex-col items-start justify-between py-4 border-t-2 border-gray-800 md:py-0 md:pt-4 md:flex-row lg:justify-between">
          <div>
            <p className={`text-white mb-2`}>
              Copyright &copy; {year} - {copyrightTitle} &nbsp;
            </p>
            <a
              href={marketerLink}
              target="_blank"
              rel="noreferrer"
              className={`block my-3 md:my-0 ${styles.hoverStyle}`}
            >
              {marketerTitle}
            </a>
          </div>
          <div className="flex flex-wrap items-center justify-between w-full md:w-auto">
            {/* Security Overview */}
            <Link
              to="/security-overview"
              className={`w-1/2 mb-2 md:mb-0 lg:w-full lg:mr-3 text-white ${styles.hoverStyle}`}
            >
              Security Overview
            </Link>
            {/* GDPR Policy */}
            <Link
              to="/gdpr-policy"
              className={`w-1/2 mb-2 md:mb-0 lg:w-full lg:mr-3 text-white ${styles.hoverStyle}`}
            >
              GDPR Policy
            </Link>
            <Link
              to="/privacy-policy"
              className={`w-1/2 lg:w-full lg:mr-3 text-white ${styles.hoverStyle}`}
            >
              Privacy Policy
            </Link>
            {/* Terms of Service Link */}
            <Link
              to="/terms-of-service"
              className={`w-1/2 lg:w-full lg:mr-3 text-white ${styles.hoverStyle}`}
            >
              Terms of Service
            </Link>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
