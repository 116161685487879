import React from "react"
import { Link } from "gatsby"

import * as styles from "./index.module.scss"

const TopBanner = () => {
  return (
    <div className={styles.newsBanner}>
      <Link to="/b2b-ninja-is-now-part-of-bigcommerce">
        <p className="md:font-medium text-base py-2">
          B2B Ninja is now part of BigCommerce. Same team. Same support. New horizons.
        </p>
      </Link>
    </div>
  )
}

export default TopBanner
