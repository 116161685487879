import React from "react"

export const Logo = ({ size = 1 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={442 * size}
      height={76 * size}
      data-name="Layer 2"
      viewBox="0 0 442.29 76.7"
    >
      <path
        d="M340.3 401.54h18.87c4.63 0 7.89 1.16 10 3.32a8 8 0 012.37 5.94v.1c0 4.26-2.42 6.79-5.68 8.21 4.47 1.47 7.31 4 7.31 9v.1c0 6.31-5.15 10.15-13.93 10.15h-19zm16.35 14.67c3.1 0 4.84-1 4.84-3.2v-.11c0-2-1.58-3.15-4.63-3.15h-6.63v6.46zm1.47 13.94c3.1 0 4.89-1.21 4.89-3.37v-.1c0-2-1.58-3.32-5-3.32h-7.78v6.79zM376.36 430.67l13.78-10.51c3.42-2.68 4.73-4.42 4.73-6.63s-1.63-3.73-4.1-3.73-4.42 1.42-7.31 4.73l-6.89-5.73c3.95-5.1 7.84-7.89 15-7.89 8 0 13.57 4.79 13.57 11.73v.1c0 5.89-3.05 8.94-8.52 12.89L390.3 430h15.19v8.31h-29.13zM410.7 401.54h18.87c4.63 0 7.89 1.16 10.05 3.32a8.06 8.06 0 012.38 5.94v.1c0 4.26-2.42 6.79-5.68 8.21 4.47 1.47 7.31 4 7.31 9v.1c0 6.31-5.15 10.15-13.93 10.15h-19zm16.35 14.67c3.1 0 4.84-1 4.84-3.2v-.11c0-2-1.58-3.15-4.63-3.15h-6.62v6.46zm1.47 13.94c3.1 0 4.89-1.21 4.89-3.37v-.1c0-2-1.57-3.32-5-3.32h-7.78v6.79zM291.1 379.9a21 21 0 0114.95 20.1 21 21 0 01-21 21h-63.81l-29.53 17.36h130.43v-76.7z"
        transform="translate(-191.71 -361.65)"
      ></path>
      <path
        d="M253.63 398.77c1.51 5-4.74 11.17-14 10.38s-14.32-8-12-12.65 8.26-6.66 13.62-6.28c5.4.56 10.92 3.49 12.38 8.55zM265.67 398.77c-1.51 5 4.74 11.17 14 10.38s14.32-8 12-12.65-8.27-6.66-13.62-6.28c-5.39.56-10.91 3.49-12.38 8.55zM483.81 403.5h3.78v34.36h-3.1l-22.18-28.18v28.18h-3.78V403.5h3.63l21.65 27.5zM501.53 403.5v34.36h-3.88V403.5zM536.87 403.5h3.78v34.36h-3.1l-22.18-28.18v28.18h-3.78V403.5h3.63l21.65 27.5zM567.48 431.65a10.5 10.5 0 01-2.21 3.73 8.76 8.76 0 01-3.37 2.23 12.08 12.08 0 01-4.27.74 11.22 11.22 0 01-6.43-1.72 13.93 13.93 0 01-4.17-4.37l2.8-2.4a12.89 12.89 0 003.36 3.65 7.6 7.6 0 004.49 1.26 6.26 6.26 0 004.81-2.09q1.92-2.08 1.92-6.16v-23h3.87v23a15.2 15.2 0 01-.8 5.13zM608.29 437.86h-4.17l-4-9.08h-18.7l-4.08 9.08h-4L589 403.25h3.63zm-17.53-30l-7.85 17.52h15.66z"
        transform="translate(-191.71 -361.65)"
      ></path>
      <text className="cls-1" transform="translate(417.02 48.59)">
        TM
      </text>
    </svg>
  )
}

export const HomeRight = ({ size = 10, pathClass }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size * 0.67} height={size}>
      <path
        d="M9.433 9.997L.496 2.434a1.275 1.275 0 010-2.019 1.928 1.928 0 012.391 0l10.125 8.569a1.274 1.274 0 01.049 1.971L2.894 19.583a1.922 1.922 0 01-2.391 0 1.275 1.275 0 010-2.019z"
        className={pathClass}
      ></path>
    </svg>
  )
}

export const RightArrow = ({ size = 20, color = "#fff", className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={size}
      width={size}
      enableBackground="new 0 0 492.004 492.004"
      version="1.1"
      viewBox="0 0 492.004 492.004"
      xmlSpace="preserve"
      fill={color}
      className={className}
    >
      <path
        fill={color}
        d="M484.14 226.886L306.46 49.202c-5.072-5.072-11.832-7.856-19.04-7.856-7.216 0-13.972 2.788-19.044 7.856l-16.132 16.136c-5.068 5.064-7.86 11.828-7.86 19.04 0 7.208 2.792 14.2 7.86 19.264L355.9 207.526H26.58C11.732 207.526 0 219.15 0 234.002v22.812c0 14.852 11.732 27.648 26.58 27.648h330.496L252.248 388.926c-5.068 5.072-7.86 11.652-7.86 18.864 0 7.204 2.792 13.88 7.86 18.948l16.132 16.084c5.072 5.072 11.828 7.836 19.044 7.836 7.208 0 13.968-2.8 19.04-7.872l177.68-177.68c5.084-5.088 7.88-11.88 7.86-19.1.016-7.244-2.776-14.04-7.864-19.12z"
      ></path>
    </svg>
  )
}

export const Signature = ({
  width,
  height,
  color = "#fff",
  className,
  pathClass,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={height ? height * 2 : "21.483"}
      height={height || "12.895"}
      viewBox="0 0 21.483 12.895"
      className={className}
    >
      <path
        fill={color}
        className={pathClass}
        d="M20.926 4.301c-1.739.118-4.221 1.837-5.477 2.4a6.95 6.95 0 01-2.555.819c-.759 0-.873-.544-.715-1.743.037-.269.393-2.66-1.434-2.555-.843.05-2.159.833-5.692 4.231L6.446 3.97A2.9 2.9 0 002.091.518L.247 1.757a.54.54 0 00-.164.744l.578.9a.534.534 0 00.742.165L3.35 2.264a.75.75 0 011.1.91l-3.3 8.247a1.073 1.073 0 001 1.474 1.062 1.062 0 00.759-.316c1.415-1.417 5.189-5.06 7.089-6.578a3.485 3.485 0 00.692 2.814 2.834 2.834 0 002.2.85 8.492 8.492 0 003.435-1.007c1.108-.5 3.324-2.1 4.647-2.21a.539.539 0 00.51-.531V4.843a.533.533 0 00-.557-.544z"
      ></path>
    </svg>
  )
}

export const SignOut = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22.335"
      height="17.102"
      viewBox="0 0 22.335 17.102"
    >
      <path
        className={className}
        d="M18.147 16.751h-3.664a.525.525 0 01-.523-.523v-1.745a.525.525 0 01.523-.523h3.664a1.394 1.394 0 001.4-1.4V4.188a1.394 1.394 0 00-1.4-1.4h-3.664a.525.525 0 01-.523-.523V.523A.525.525 0 0114.483 0h3.664a4.189 4.189 0 014.188 4.188v8.375a4.189 4.189 0 01-4.188 4.188zM16.1 7.983L8.768.654A1.049 1.049 0 006.98 1.4v4.188H1.047A1.044 1.044 0 000 6.631v4.188a1.044 1.044 0 001.047 1.047H6.98v4.188a1.049 1.049 0 001.789.742l7.331-7.33a1.056 1.056 0 000-1.483z"
      ></path>
    </svg>
  )
}

export const Play = ({ size = 124, color = "#fff", className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      enableBackground="new 0 0 124.512 124.512"
      version="1.1"
      viewBox="0 0 124.512 124.512"
      xmlSpace="preserve"
      className={className}
    >
      <path
        fill={color}
        d="M113.956 57.006l-97.4-56.2c-4-2.3-9 .6-9 5.2v112.5c0 4.6 5 7.5 9 5.2l97.4-56.2c4-2.401 4-8.2 0-10.5z"
      ></path>
    </svg>
  )
}

export const Chat = ({ size = 1, color, className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={98.29 * size}
      height={90.257 * size}
      viewBox="0 0 98.29 90.257"
      className={className}
    >
      <path
        fill={color}
        d="M82.842 0H15.433A15.454 15.454 0 000 15.433v36.188a15.451 15.451 0 0015.433 15.433h25.953l33.658 23.2-.6-23.2h8.416a15.447 15.447 0 0015.433-15.433V15.433A15.451 15.451 0 0082.842 0zm8.337 51.621a8.344 8.344 0 01-8.337 8.337h-15.7l.44 16.533-23.984-16.536H15.433a8.345 8.345 0 01-8.337-8.337V15.43a8.348 8.348 0 018.337-8.337h67.409a8.348 8.348 0 018.337 8.337z"
      ></path>
    </svg>
  )
}

export const Phone = ({ size = 1, color, className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={98.29 * size}
      height={90.257 * size}
      viewBox="0 0 88.105 98.355"
      className={className}
    >
      <path
        fill={color}
        d="M83.502 44.754l-1.71-.99v-6.742a37.02 37.02 0 10-74.041 0v5.912l-3.146 1.82A8.843 8.843 0 00-.003 52.42v7.045a8.639 8.639 0 002.876 6.346c.055 16.965 10.784 28.553 26.947 29.377a5.5 5.5 0 004.949 3.168h4.163a5.5 5.5 0 100-11h-4.163a5.5 5.5 0 00-4.949 3.063c-12.528-.715-20.87-8.892-22.08-21.5l.083.044a2.365 2.365 0 002.288 1.782h3.569a7.116 7.116 0 007.111-7.111V48.213a7.116 7.116 0 00-7.111-7.105h-1.188v-4.086A32.229 32.229 0 0125.838 10.91l.177.293a7.336 7.336 0 006.308 3.657 7.068 7.068 0 001.876-.253 41.471 41.471 0 0121.386 0 7.281 7.281 0 008.183-3.4l.088-.159a32.231 32.231 0 0113.16 25.979v4.086h-2.629a7.116 7.116 0 00-7.094 7.105V63.67a7.116 7.116 0 007.111 7.094h3.569a2.365 2.365 0 002.288-1.782l3.239-1.864a8.865 8.865 0 004.6-7.666V52.42a8.849 8.849 0 00-4.6-7.666zM4.757 52.42a4.163 4.163 0 012.227-3.553l.77-.445v15.035l-.77-.445a4.146 4.146 0 01-2.227-3.547zm78.591 7.045a4.146 4.146 0 01-2.2 3.547l-.77.445V48.422l.77.445a4.163 4.163 0 012.2 3.553z"
      ></path>
    </svg>
  )
}

export const Base = ({ size = 1, color, className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={98.29 * size}
      height={90.257 * size}
      viewBox="0 0 100.328 100.319"
      className={className}
    >
      <defs>
        <linearGradient
          id="a"
          x1="0.136"
          x2="0.798"
          y1="0.159"
          y2="0.765"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#4100a3"></stop>
          <stop offset="1" stopColor="#0d52ff"></stop>
        </linearGradient>
      </defs>
      <path
        d="M98.293 43.783a1.984 1.984 0 00-.414.046 48.389 48.389 0 00-28.9-37.915A47.31 47.31 0 0058.243 2.77 2.071 2.071 0 0056.319.044L45.441.001a2.063 2.063 0 00-2.052 2.067 2.031 2.031 0 00.066.523 47.317 47.317 0 00-11.889 3.323A48.4 48.4 0 003.039 41.566a1.976 1.976 0 00-.944-.243A2.063 2.063 0 00.043 43.39l-.047 11.066a2.063 2.063 0 002.035 2.082 2 2 0 00.551-.076 48.392 48.392 0 0028.987 38.629 47.415 47.415 0 0010.067 3.027v.076a2.062 2.062 0 002.03 2.083l10.878.043a2.059 2.059 0 002.034-1.835 47.277 47.277 0 0012.405-3.394 48.4 48.4 0 0028.625-36.2 1.979 1.979 0 00.618.1 2.065 2.065 0 002.052-2.067l.046-11.065a2.063 2.063 0 00-2.035-2.084zM50.693 5.9a43.952 43.952 0 0114.578 2.54l-5.23 14.714a30.934 30.934 0 00-18.9.275l-4.15-15.4a43.979 43.979 0 0113.698-2.13zm-29.02 52.7L7.564 61.719a44.866 44.866 0 011.625-27.442l14.241 4.952A28.965 28.965 0 0021.673 58.6zm28.648 36.514a43.928 43.928 0 01-15.722-2.964l5.252-13.293a30.938 30.938 0 0019.47.946l2.928 13.72a44 44 0 01-11.931 1.593zm.584-17.634c-14.809-.059-26.807-11.82-26.747-26.22s12.156-26.055 26.964-26 26.806 11.82 26.745 26.22-12.158 26.06-26.966 26.002zm40.708-10.212l-12.54-4.852a28.944 28.944 0 00.768-20.017l13.5-3.517A44.851 44.851 0 0191.61 67.27z"
        className="a"
      ></path>
    </svg>
  )
}

export const VerifiedBlue = ({ ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <path
        fill="#2552FF"
        d="M10 0C4.485 0 0 4.485 0 10s4.485 10 10 10 10-4.485 10-10S15.515 0 10 0z"
      ></path>
      <path
        fill="#FFF"
        d="M14.348 6.667l-5.515 5.612-3.155-3.373-1.511 1.587 3.897 4.167c.213.227.478.34.743.34s.53-.113.742-.312l6.284-6.377-1.485-1.644z"
      ></path>
    </g>
  </svg>
)

export const Website = ({ width = 32, height = 32, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={width}
    viewBox="0 0 25 25"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <rect width="25" height="25" fill="#fff" rx="12.5"></rect>
      <g
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        transform="translate(6 6)"
      >
        <circle cx="6.391" cy="6.391" r="6.391"></circle>
        <path d="M10.563 1.554a6.366 6.366 0 01-4.172 1.554 6.36 6.36 0 01-4.172-1.547m0 9.667a6.366 6.366 0 014.172-1.554c1.596 0 3.052.588 4.172 1.554"></path>
        <path d="M6.391 0c-1.61 1.274-2.695 3.661-2.695 6.391s1.085 5.117 2.695 6.391c1.61-1.274 2.695-3.661 2.695-6.391S8.001 1.274 6.391 0zM0 6.406h12.782"></path>
      </g>
    </g>
  </svg>
)

export const QuoteLarge = ({ width, height, color = "#0d52ff", ...rest }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="351.667"
    height="357"
    viewBox="0 0 351.667 357"
    {...rest}
  >
    <path
      d="M175.83 0C78.875 0-.004 80.073-.004 178.5S78.875 357 175.83 357s175.834-80.073 175.834-178.5S272.789 0 175.83 0zm0 334.827c-85.481 0-155.038-70.127-155.038-156.327S90.353 22.173 175.83 22.173 330.872 92.3 330.872 178.5 261.314 334.827 175.83 334.827z"
      fill={color}
    ></path>
    <path
      d="M162.607 120.447H91.159a9.313 9.313 0 00-9.138 9.426v53.177a9.319 9.319 0 009.138 9.43h43.021q-9.737 32.315-52.159 53.9l12.549 13.343c59.813-9.785 73.913-60.158 76.613-73.437a9.547 9.547 0 00.565-3.221v-53.177a9.32 9.32 0 00-9.141-9.441zM264.647 120.447h-71.45a9.316 9.316 0 00-9.126 9.426v53.177a9.32 9.32 0 009.141 9.43h43q-9.737 32.315-52.159 53.9l12.534 13.343c59.828-9.785 73.931-60.158 76.628-73.437a9.543 9.543 0 00.565-3.221v-53.177a9.317 9.317 0 00-9.133-9.441z"
      fill={color}
    ></path>
  </svg>
)

export const Crown = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="45"
      height="36"
      viewBox="0 0 45 36"
      className={className}
    >
      <defs>
        <linearGradient
          id="a"
          x1="0.326"
          x2="0.5"
          y1="0.316"
          y2="0.803"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#ffdd31"></stop>
          <stop offset="1" stopColor="#c9a236"></stop>
        </linearGradient>
      </defs>
      <g
        style={{
          fill: "url(#a)",
        }}
      >
        <path
          style={{
            stroke: "none",
          }}
          d="M37.125 35.5H7.875a.626.626 0 01-.625-.625v-2.25c0-.345.28-.625.625-.625h29.25c.345 0 .625.28.625.625v2.25c0 .345-.28.625-.625.625zm-1.471-6.75H9.346L4.385 15.518l-.144-.383-.404.065c-.177.028-.323.05-.462.05A2.878 2.878 0 01.5 12.375a2.878 2.878 0 015.757 0c0 .396-.09.796-.265 1.187l-.183.405.381.229 5.084 3.051c.425.256.912.392 1.408.392.987 0 1.903-.532 2.391-1.388l5.73-10.026.21-.367-.326-.268a2.867 2.867 0 01-1.062-2.215A2.878 2.878 0 0122.5.5a2.878 2.878 0 012.875 2.875c0 .853-.387 1.66-1.062 2.215l-.326.268.21.367 5.73 10.026a2.755 2.755 0 002.388 1.388c.495 0 .983-.136 1.41-.391l5.091-3.052.385-.23-.187-.407a2.821 2.821 0 01-.264-1.184A2.878 2.878 0 0141.625 9.5a2.878 2.878 0 012.875 2.875 2.878 2.878 0 01-2.875 2.875c-.14 0-.285-.022-.462-.05l-.404-.065-.144.383-4.961 13.232z"
        ></path>
        <path
          style={{
            fill: "#727272",
            stroke: "none",
          }}
          d="M37.125 35a.128.128 0 00.125-.125v-2.25a.128.128 0 00-.125-.125H7.875a.128.128 0 00-.125.125v2.25c0 .067.058.125.125.125h29.25m-1.818-6.75l4.84-12.907.287-.766.808.13c.128.02.273.043.383.043A2.378 2.378 0 0044 12.375 2.378 2.378 0 0041.625 10a2.378 2.378 0 00-2.375 2.375c0 .336.071.655.218.975l.374.814-.768.46-5.092 3.053a3.252 3.252 0 01-1.667.462 3.256 3.256 0 01-2.822-1.64l-5.73-10.026-.42-.733.652-.536c.56-.46.88-1.126.88-1.829A2.378 2.378 0 0022.5 1a2.378 2.378 0 00-2.375 2.375c0 .703.32 1.37.88 1.83l.651.535-.418.733-5.73 10.026a3.26 3.26 0 01-2.826 1.64c-.586 0-1.162-.16-1.665-.463l-5.084-3.051-.762-.458.365-.81c.147-.326.221-.657.221-.982a2.378 2.378 0 00-4.757 0 2.378 2.378 0 002.375 2.375c.11 0 .255-.023.383-.044l.808-.129.287.766 4.84 12.907h25.614M37.125 36H7.875a1.128 1.128 0 01-1.125-1.125v-2.25c0-.619.506-1.125 1.125-1.125h29.25c.619 0 1.125.506 1.125 1.125v2.25c0 .619-.506 1.125-1.125 1.125zM36 29.25H9L3.916 15.694a3.446 3.446 0 01-.541.056A3.376 3.376 0 010 12.375 3.382 3.382 0 013.382 9a3.376 3.376 0 013.375 3.375c0 .5-.12.97-.31 1.392l5.084 3.052a2.25 2.25 0 003.108-.816l5.73-10.026a3.36 3.36 0 01-1.244-2.602 3.376 3.376 0 016.75 0 3.36 3.36 0 01-1.245 2.602l5.73 10.026a2.245 2.245 0 003.109.816l5.09-3.052a3.307 3.307 0 01-.309-1.392 3.376 3.376 0 113.375 3.375c-.183 0-.366-.028-.541-.056L36 29.25z"
        ></path>
      </g>
    </svg>
  )
}

export const RibbonBlack = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="34.998"
      height="40"
      viewBox="0 0 34.998 40"
    >
      <defs>
        <linearGradient
          id="rib"
          x1="0.326"
          x2="0.5"
          y1="0.316"
          y2="0.803"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#616161"></stop>
          <stop offset="1" stopColor="#000000"></stop>
        </linearGradient>
      </defs>
      <path
        style={{
          fill: "url(#rib)",
        }}
        d="M.472 34.712A1.868 1.868 0 00.824 37.5l5.372 2.18a1.887 1.887 0 002.445-.3l7.172-7.961-6.184-6.864L.472 34.712zm34.048 0S11.707 9.352 11.441 8.922a12.347 12.347 0 016.055-1.4 12.347 12.347 0 016.055 1.4c-.258.438-4.375 5.047-4.375 5.047l6.18 6.852 2.672-2.969a7.644 7.644 0 00.891-9.024l-3.415-5.742a4.43 4.43 0 00-1.308-1.406c-3.18-2.156-9.953-2.32-13.391 0a4.43 4.43 0 00-1.317 1.406L6.082 8.8a7.707 7.707 0 00.9 9.063l19.381 21.513a1.878 1.878 0 002.445.3l5.375-2.18a1.857 1.857 0 00.336-2.781z"
      ></path>
    </svg>
  )
}

export const RibbonBlue = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="34.998"
      height="40"
      viewBox="0 0 34.998 40"
    >
      <defs>
        <linearGradient
          id="ribl"
          x1="0.326"
          x2="0.5"
          y1="0.316"
          y2="0.803"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#0d52ff"></stop>
          <stop offset="1" stopColor="#4100a3"></stop>
        </linearGradient>
      </defs>
      <path
        style={{
          fill: "url(#ribl)",
        }}
        d="M.472 34.712A1.868 1.868 0 00.824 37.5l5.372 2.18a1.887 1.887 0 002.445-.3l7.172-7.961-6.184-6.864L.472 34.712zm34.048 0S11.707 9.352 11.441 8.922a12.347 12.347 0 016.055-1.4 12.347 12.347 0 016.055 1.4c-.258.438-4.375 5.047-4.375 5.047l6.18 6.852 2.672-2.969a7.644 7.644 0 00.891-9.024l-3.415-5.742a4.43 4.43 0 00-1.308-1.406c-3.18-2.156-9.953-2.32-13.391 0a4.43 4.43 0 00-1.317 1.406L6.082 8.8a7.707 7.707 0 00.9 9.063l19.381 21.513a1.878 1.878 0 002.445.3l5.375-2.18a1.857 1.857 0 00.336-2.781z"
      ></path>
    </svg>
  )
}

export const RibbonStroke = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="34.998"
      height="40"
      viewBox="0 0 34.998 40"
    >
      <path
        style={{
          stroke: "black",
          fill: "#fff",
        }}
        d="M.472 34.712A1.868 1.868 0 00.824 37.5l5.372 2.18a1.887 1.887 0 002.445-.3l7.172-7.961-6.184-6.864L.472 34.712zm34.048 0S11.707 9.352 11.441 8.922a12.347 12.347 0 016.055-1.4 12.347 12.347 0 016.055 1.4c-.258.438-4.375 5.047-4.375 5.047l6.18 6.852 2.672-2.969a7.644 7.644 0 00.891-9.024l-3.415-5.742a4.43 4.43 0 00-1.308-1.406c-3.18-2.156-9.953-2.32-13.391 0a4.43 4.43 0 00-1.317 1.406L6.082 8.8a7.707 7.707 0 00.9 9.063l19.381 21.513a1.878 1.878 0 002.445.3l5.375-2.18a1.857 1.857 0 00.336-2.781z"
      ></path>
    </svg>
  )
}

export const Check = ({ size = 12, color, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 -46 417.813 417"
      {...props}
    >
      <path
        fill={color}
        d="M159.988 318.582c-3.988 4.012-9.43 6.25-15.082 6.25s-11.094-2.238-15.082-6.25L9.375 198.113c-12.5-12.5-12.5-32.77 0-45.246l15.082-15.086c12.504-12.5 32.75-12.5 45.25 0l75.2 75.203L348.104 9.781c12.504-12.5 32.77-12.5 45.25 0l15.082 15.086c12.5 12.5 12.5 32.766 0 45.246zm0 0"
      ></path>
    </svg>
  )
}

export const CheckInvert = ({ size = 12, color, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      data-name="Layer 1"
      viewBox="0 0 18 18"
      {...props}
    >
      <path
        d="M8.72-.28a9 9 0 109 9 9 9 0 00-9.02-9zm0 17.48a8.48 8.48 0 118.48-8.48 8.47 8.47 0 01-8.48 8.48z"
        transform="translate(.28 .28)"
      ></path>
      <path
        d="M14.55 7.2l-6.48 6.49a1.08 1.08 0 01-.76.31 1.06 1.06 0 01-.75-.31L2.91 10a1.05 1.05 0 010-1.48l.79-.79a1 1 0 011.5 0l2.11 2.12 4.93-4.93a1 1 0 01.76-.31 1.06 1.06 0 01.75.31l.79.8a1.05 1.05 0 01.01 1.48z"
        transform="translate(.28 .28)"
      ></path>
    </svg>
  )
}

export const FooterPhone = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="0 0 28 28"
    >
      <path
        fill={color}
        d="M27.2 19.786l-6.125-2.625a1.313 1.313 0 00-1.531.377l-2.713 3.314a20.271 20.271 0 01-9.69-9.69l3.315-2.712a1.309 1.309 0 00.377-1.531L8.209.794A1.321 1.321 0 006.7.033L1.017 1.346A1.313 1.313 0 000 2.625 25.373 25.373 0 0025.375 28a1.313 1.313 0 001.28-1.017l1.313-5.683a1.328 1.328 0 00-.766-1.509z"
      ></path>
    </svg>
  )
}

export const FooterChat = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="0 0 28 28"
    >
      <path
        d="M25.2 0H2.8A2.8 2.8 0 00.014 2.8L0 28l5.6-5.6h19.6a2.808 2.808 0 002.8-2.8V2.8A2.808 2.808 0 0025.2 0zM5.6 9.8h16.8v2.8H5.6zm11.2 7H5.6V14h11.2zm5.6-8.4H5.6V5.6h16.8z"
        fill={color}
      ></path>
    </svg>
  )
}

export const FooterDB = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="0 0 28 28"
    >
      <path
        d="M14 0C6.268 0 0 1.959 0 4.375v3.497c0 2.416 6.268 4.375 14 4.375s14-1.959 14-4.375v-3.5C28 1.959 21.729 0 14 0zm0 14.875c-7.732 0-14-1.959-14-4.375v5.25c0 2.416 6.268 4.375 14 4.375s14-1.959 14-4.375V10.5c0 2.416-6.271 4.372-14 4.372zm0 7.875c-7.732 0-14-1.959-14-4.375v5.25C0 26.041 6.268 28 14 28s14-1.959 14-4.375v-5.253c0 2.416-6.268 4.375-14 4.375z"
        fill={color}
      ></path>
    </svg>
  )
}

export const ChevronRight = ({ color = "#fff", size = 18, className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      enableBackground="new 0 0 444.819 444.819"
      version="1.1"
      viewBox="0 0 444.819 444.819"
      xmlSpace="preserve"
      height={size}
      width={size}
    >
      <path
        className={className}
        d="M352.025 196.712L165.884 10.848C159.029 3.615 150.469 0 140.187 0s-18.842 3.619-25.697 10.848L92.792 32.264c-7.044 7.043-10.566 15.604-10.566 25.692 0 9.897 3.521 18.56 10.566 25.981L231.545 222.41 92.786 361.168c-7.042 7.043-10.564 15.604-10.564 25.693 0 9.896 3.521 18.562 10.564 25.98l21.7 21.413c7.043 7.043 15.612 10.564 25.697 10.564 10.089 0 18.656-3.521 25.697-10.564L352.025 248.39c7.046-7.423 10.571-16.084 10.571-25.981.001-10.088-3.525-18.654-10.571-25.697z"
      ></path>
    </svg>
  )
}

export const LinkIcon = ({ color = "#fff", size = 14, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      x="0"
      y="0"
      enableBackground="new 0 0 512 512"
      version="1.1"
      viewBox="0 0 512 512"
      xmlSpace="preserve"
      {...props}
    >
      <path d="M488.727 0H302.545c-12.853 0-23.273 10.42-23.273 23.273s10.42 23.273 23.273 23.273h129.997L192.999 286.09c-9.089 9.089-9.089 23.823 0 32.912a23.195 23.195 0 0016.455 6.816 23.194 23.194 0 0016.457-6.817L465.455 79.458v129.997c0 12.853 10.42 23.273 23.273 23.273s23.273-10.42 23.273-23.273V23.273C512 10.42 501.58 0 488.727 0z"></path>
      <path d="M395.636 232.727c-12.853 0-23.273 10.42-23.273 23.273v209.455H46.545V139.636H256c12.853 0 23.273-10.42 23.273-23.273S268.853 93.091 256 93.091H23.273C10.42 93.091 0 103.511 0 116.364v372.364C0 501.58 10.42 512 23.273 512h372.364c12.853 0 23.273-10.42 23.273-23.273V256c-.001-12.853-10.421-23.273-23.274-23.273z"></path>
    </svg>
  )
}

export const Arrow = ({ height = 61, width = 98, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 98 61"
    {...props}
  >
    <path
      fill="#1268A0"
      fillRule="evenodd"
      d="M61.925 57.703c.906-1.087 5.404-4.32 11.342-8.17a621.243 621.243 0 007.054-4.629l2.702-1.812-7.852-.208c-4.306-.118-9.148-.371-10.735-.58-6.918-.878-14.48-2.273-18.333-3.387-4.406-1.268-6.029-1.776-6.365-1.984-.217-.136-.516-.245-.707-.245-.544-.027-3.826-1.213-4.986-1.793-.608-.308-1.932-.888-2.893-1.277-6.428-2.564-15.032-8.225-19.774-13.008C5.947 15.112 1.903 8.49.724 3.101c-1.487-6.685 4.706.77 10.028 8.225 3.137 4.393 11.27 10.888 18.152 14.493 9.665 5.054 21.062 8.542 33.438 10.226 4.343.59 18.768.97 22.023.571l2.248-.272-2.085-.878c-1.151-.49-7.008-2.827-13.056-5.2-14.407-5.68-15.812-6.322-16.066-7.382-.127-.544-.01-1.123.344-1.667.807-1.24-.127-1.521 21.87 6.604 4.433 1.64 9.12 3.37 10.444 3.867 1.342.499 3.7 1.377 5.304 1.966 2.766 1.014 3.99 1.721 3.609 2.092-.091.082.036.535.28 1.015.264.516.318.915.137.978-.182.073-.318.498-.318.987 0 1.205-1.677 3.09-4.161 4.674-5.594 3.57-16.057 9.756-16.239 9.584-.108-.118-.181-.073-.181.118 0 .163-.626.643-1.37 1.06-.743.407-2.746 1.585-4.424 2.59-5.104 3.08-7.008 3.94-8.196 3.714-1.414-.272-1.677-1.477-.58-2.763z"
    ></path>
  </svg>
)

export const ArrowBottom = ({ height = 97, width = 60, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 60 97"
    {...props}
  >
    <path
      fill="#1268A0"
      fillRule="evenodd"
      d="M57.203 61.425c-1.087.906-4.32 5.404-8.17 11.342a621.243 621.243 0 01-4.629 7.054l-1.812 2.702-.208-7.852c-.118-4.306-.371-9.148-.58-10.735-.878-6.918-2.273-14.48-3.387-18.333-1.268-4.406-1.776-6.029-1.984-6.365-.136-.217-.245-.516-.245-.707-.027-.544-1.213-3.826-1.793-4.986-.308-.608-.888-1.932-1.277-2.893-2.564-6.428-8.225-15.032-13.008-19.774C14.612 5.447 7.99 1.403 2.601.224c-6.685-1.487.77 4.706 8.225 10.028 4.393 3.137 10.888 11.27 14.493 18.152 5.054 9.665 8.541 21.062 10.226 33.438.59 4.343.97 18.768.571 22.023l-.272 2.248-.878-2.085c-.49-1.151-2.827-7.008-5.2-13.056-5.68-14.407-6.322-15.812-7.382-16.066-.544-.127-1.123-.01-1.667.344-1.24.807-1.521-.127 6.604 21.87 1.64 4.433 3.37 9.12 3.867 10.444.499 1.342 1.377 3.7 1.966 5.304 1.014 2.766 1.721 3.99 2.092 3.609.082-.091.535.036 1.015.28.516.264.915.318.978.137.073-.182.498-.318.987-.318 1.205 0 3.09-1.677 4.674-4.161 3.57-5.594 9.756-16.057 9.584-16.239-.118-.108-.073-.181.118-.181.163 0 .643-.626 1.06-1.37.407-.743 1.585-2.746 2.59-4.424 3.08-5.104 3.94-7.008 3.714-8.196-.272-1.414-1.477-1.677-2.763-.58z"
    ></path>
  </svg>
)

export const CloseBold = ({
  height = 32,
  width = 32,
  color = "#000",
  ...props
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 40 40"
    {...props}
  >
    <g fill="none">
      <path
        fill="#FFF"
        d="M19.92 39.84C8.936 39.84 0 30.904 0 19.92 0 8.936 8.936 0 19.92 0c10.984 0 19.92 8.936 19.92 19.92 0 10.984-8.936 19.92-19.92 19.92z"
      ></path>
      <path
        fill="#231F20"
        d="M26.991 23.003l-3.535-3.536 3.535-3.535a2.501 2.501 0 00-3.535-3.536l-3.536 3.536-3.536-3.536a2.5 2.5 0 10-3.535 3.536l3.535 3.535-3.535 3.536a2.501 2.501 0 003.535 3.536l3.536-3.536 3.536 3.536a2.5 2.5 0 103.535-3.536z"
      ></path>
    </g>
  </svg>
)
