import React from "react"
import { SignOut } from "../icons"
import { RecordAnalyticsEvent } from "../utils"

import * as styles from "./index.module.scss"

const TrialButton = props => {
  return (
    <a
      href="https://login.bigcommerce.com/deep-links/marketplace/apps/5627?referral_from=b2bninja.com"
      target="_blank"
      rel="noreferrer"
      className={`${styles.freeTrialButton} ${
        props.flexible && styles.flexibleButton
      } ${props.altHover && styles.altHover} ${props.className}`}
      onClick={() => {
        RecordAnalyticsEvent("click", "bc-install-page", {
          from: "header",
        })
      }}
    >
      {props.children || "Free Trial"}
      <div className={`z-10 ${styles.iconContainer}`}>
        <SignOut className={styles.trialButtonIcon} />
      </div>
    </a>
  )
}

export default TrialButton
