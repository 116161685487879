import React, { useState } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"

import TrialButton from "../trialButton"
import { Play, RightArrow, Signature, LinkIcon } from "../icons"
import Logo from "../../assets/images/logo.png"
import * as styles from "./index.module.scss"

const Navbar = () => {
  const [dropdown, setDropdown] = useState(false)
  const [subMenuOpen, setSubMenuOpen] = useState(false)

  const {
    products: { edges },
  } = useStaticQuery(graphql`
    query productPageQueryAndProductPageQuery {
      products: allContentfulProductsPageTemplate(
        filter: { slug: { ne: "dummy-page" } }
      ) {
        edges {
          node {
            slug
            bannerTitle
          }
        }
      }
    }
  `)

  return (
    <div className={`${styles.navbarContainer}`}>
      <div className={`${styles.navbar} flex items-center max-w-6xl mx-auto`}>
        <div className="pl-4 lg:pl-0">
          <Link to="/">
            <img
              src={Logo}
              alt="B2B Ninja"
              loading="eager"
              className="w-4/5 lg:w-10/12"
            />
            {/* <Logo size={0.45} /> */}
          </Link>
        </div>
        <div className="pr-4 lg:pr-0 flex items-center justify-end py-3 flex-1 text-right block lg:hidden">
          <button onClick={() => setDropdown(!dropdown)}>
            <span className={`${styles.toggleIcon}`} />
          </button>
        </div>
        <div
          className={`${!dropdown && styles.mobileHidden} ${
            styles.mainNavigation
          } lg:flex-1 flex gap-y-4 flex-col items-center lg:flex-row z-50 py-12 lg:py-0`}
        >
          <ul className="lg:items-center flex flex-col lg:flex-row flex-1 lg:gap-2 xl:gap-5">
            <li className={` ${styles.linkWrapper}`}>
              <button
                className={`${styles.navLinks}  ${styles.haveSub}`}
                onClick={() => setSubMenuOpen(!subMenuOpen)}
              >
                Modules
              </button>
              <span className="text-white">
                <button
                  onClick={() => setSubMenuOpen(!subMenuOpen)}
                  className={`outline-none`}
                >
                  <Play
                    size={16}
                    color={subMenuOpen ? "#0056F2" : "#fff"}
                    className={`${subMenuOpen && styles.activeIcon} lg:hidden`}
                  />
                </button>
              </span>
              <ul
                className={`${!subMenuOpen && styles.mobileHidden} ${
                  styles.subMenu
                } flex flex-col w-full`}
              >
                <li className={`${styles.subLink}`}>
                  <Link to="/quotes">Quotes</Link>
                </li>
                {edges &&
                  edges.map(({ node }) => (
                    <li className={` ${styles.subLink}`} key={node.slug}>
                      <Link to={`/${node.slug}`} className=" lg:gap-x-1">
                        <div className="w-6/12 lg:w-5/12">
                          {node.bannerTitle}
                        </div>
                        <span className={`${styles.tag}`}>in the works</span>
                      </Link>
                    </li>
                  ))}
              </ul>
            </li>
            <li className={`${styles.linkWrapper}`}>
              <Link
                activeClassName={styles.activeLink}
                className={`${styles.navLinks}`}
                to="/pricing"
              >
                Pricing
              </Link>
              <span className="text-white">
                <RightArrow className="block lg:hidden" />
              </span>
            </li>
            <li className={`${styles.linkWrapper}`}>
              <Link
                activeClassName={styles.activeLink}
                className={`${styles.navLinks}`}
                to="/partners"
              >
                Partners
              </Link>
              <span className="text-white">
                <RightArrow className="block lg:hidden" />
              </span>
            </li>
            <li className={`${styles.linkWrapper}`}>
              <Link
                activeClassName={styles.activeLink}
                className={`${styles.navLinks}`}
                to="/about"
              >
                About
              </Link>
              <span className="text-white">
                <RightArrow className="block lg:hidden" />
              </span>
            </li>
            <li className={`${styles.linkWrapper}`}>
              <Link
                activeClassName={styles.activeLink}
                className={`${styles.navLinks}`}
                to="/contact"
              >
                Contact
              </Link>
              <span className="text-white">
                <RightArrow className="block lg:hidden" />
              </span>
            </li>
            <li className={`${styles.linkWrapper}`}>
              <a
                href="https://help.b2bninja.com/en/"
                target="_blank"
                rel="noreferrer"
                className={`${styles.navLinks}`}
              >
                Resources
              </a>
              <div className="pt-5">
                <LinkIcon className="hidden md:block" />
              </div>
              <span className="text-white">
                <RightArrow className="block lg:hidden" />
              </span>
            </li>
          </ul>
          <div className="lg:pt-5 flex-col lg:flex-row flex items-center">
            <a
              href="https://app.b2bninja.com"
              className={`${styles.signInButton} mb-4 md:mb-0 md:mr-2`}
            >
              <div className="pt-1">
                <Signature
                  height="16"
                  color="#000"
                  className="hidden lg:block"
                  pathClass={styles.signInIcon}
                />
              </div>
              Sign In
              <div className="pt-1">
                <Signature height="18" className="block lg:hidden" />
              </div>
            </a>

            <TrialButton altHover />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Navbar
