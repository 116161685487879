import React from "react"
import { useToggleDemo } from "../../context/popupContext"

import * as styles from "./index.module.scss"

const RevolutionizeCTA = () => {
  return (
    <div className={`${styles.ctaContainer} max-w-7xl m-auto py-8`}>
      <div className="flex flex-col items-center max-w-6xl px-4 m-auto md:px-8 xl:px-0 md:flex-row">
        <div className="flex-1">
          <h3 className="text-center text-white md:text-left">
            B2B Ninja is Here to Revolutionize Your Quoting Process
          </h3>
          {/* <p className="w-full pt-2 text-center text-white md:w-8/12 md:text-left">
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Deleniti
            iusto corrupt
          </p> */}
        </div>
        <div className="mt-8 md:mt-0">
          <button
            // href="https://calendly.com/quote-ninja/demo"
            // target="_blank"
            onClick={useToggleDemo()}
            className={`${styles.ctaButton} uppercase text-white px-8 py-2 rounded border-2 border-white`}
          >
            <div className={`${styles.content}`}>View Demo</div>
          </button>
        </div>
      </div>
    </div>
  )
}

export default RevolutionizeCTA
