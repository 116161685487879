import React, {
  createContext,
  useCallback,
  useContext,
  useReducer,
} from "react"
import { RecordAnalyticsEvent } from "../components/utils"

export const PopupStateContext = createContext()
export const PopupDispatchContext = createContext()

export const ACTIONS = {
  DEMO_TOGGLE_POPUP: "DEMO_TOGGLE_POPUP",
  DEMO_FORM_INPUT: "DEMO_FORM_INPUT",
  DEMO_SHOW_FORM: "DEMO_SHOW_FORM",
  DEMO_SHOW_CALENDAR: "DEMO_SHOW_CALENDAR",
  DEMO_SHOW_THANK_YOU: "DEMO_SHOW_THANK_YOU",
  DEMO_RESET_FORM: "DEMO_RESET_FORM",
  DEMO_SUBMIT_FORM: "DEMO_SUBMIT_FORM",
  DEMO_SUBMIT_EVENT: "DEMO_SUBMIT_EVENT",

  PARTNER_TOGGLE_POPUP: "PARTNER_TOGGLE_POPUP",
  PARTNER_FORM_INPUT: "PARTNER_FORM_INPUT",
  PARTNER_SUBMIT_FORM: "PARTNER_SUBMIT_FORM",
  PARTNER_RESET_FORM: "PARTNER_RESET_FORM",
}

// Initial state
const initialState = {
  demoForm: {
    open: false,
    scheduled: false,
    page: "video",

    bc_merchant: "",
    store_status: "",
    rev_range: "",
    sales_reps: "",

    name: "",
    email: "",
    store_name: "",
    store_url: "",
    notes: "",
  },

  partnerForm: {
    open: false,
    page: "form",

    bc_partner: "",
    name: "",
    email: "",
    agency_name: "",
    agency_url: "",
    expertise: "",
    notes: "",
  },
}

function reducer(state, { type, payload }) {
  switch (type) {
    case ACTIONS.DEMO_TOGGLE_POPUP:
      if (!state.demoForm.open) {
        RecordAnalyticsEvent("click", "dialog_opened")
      }

      return {
        ...state,
        demoForm: {
          ...state.demoForm,
          open: !state.demoForm.open,
          page: state.demoForm.open ? "video" : state.demoForm.page,
        },
      }

    case ACTIONS.DEMO_FORM_INPUT:
      return {
        ...state,
        demoForm: {
          ...state.demoForm,
          ...payload,
        },
      }

    case ACTIONS.DEMO_SHOW_FORM:
      RecordAnalyticsEvent("click", "form_opened")

      return {
        ...state,
        demoForm: {
          ...state.demoForm,
          page: "form",
          open: true,
        },
      }

    case ACTIONS.DEMO_SHOW_CALENDAR:
      RecordAnalyticsEvent("click", "calendar_opened")

      return {
        ...state,
        demoForm: {
          ...state.demoForm,
          page: "calendar",
          open: true,
        },
      }

    case ACTIONS.DEMO_SHOW_THANK_YOU:
      return {
        ...state,
        demoForm: {
          ...state.demoForm,
          open: true,
          page: "thank_you",
        },
      }

    case ACTIONS.DEMO_RESET_FORM:
      return {
        ...state,
        demoForm: {
          ...initialState.demoForm,
        },
      }

    case ACTIONS.DEMO_SUBMIT_FORM:
      fetch("https://hooks.zapier.com/hooks/catch/3242929/of1rrt1/silent/", {
        method: "POST",
        body: JSON.stringify(state.demoForm),
      }).catch()

      return {
        ...state,
        demoForm: {
          ...state.demoForm,
          page:
            state.demoForm.sales_reps === "10+" ||
            state.demoForm.rev_range === "greater-than-1m"
              ? "calendar"
              : "thank_you",
        },
      }

    case ACTIONS.DEMO_SUBMIT_EVENT:
      RecordAnalyticsEvent("click", "calendar_event_scheduled")

      return {
        ...state,
        demoForm: {
          ...state.demoForm,
          scheduled: true,
        },
      }

    case ACTIONS.PARTNER_TOGGLE_POPUP:
      if (!state.partnerForm.open) {
        RecordAnalyticsEvent("click", "partner_dialog_opened")
      }

      return {
        ...state,
        partnerForm: {
          ...state.partnerForm,
          open: !state.partnerForm.open,
        },
      }

    case ACTIONS.PARTNER_FORM_INPUT:
      return {
        ...state,
        partnerForm: {
          ...state.partnerForm,
          ...payload,
        },
      }

    case ACTIONS.PARTNER_SUBMIT_FORM:
      fetch("https://hooks.zapier.com/hooks/catch/3242929/o0jhphi/silent/", {
        method: "POST",
        body: JSON.stringify(state.partnerForm),
      }).catch()

      return {
        ...state,
        partnerForm: {
          ...state.partnerForm,
          page: "thank_you",
        },
      }

    case ACTIONS.PARTNER_RESET_FORM:
      return {
        ...state,
        partnerForm: {
          ...initialState.partnerForm,
        },
      }

    default:
      return state
  }
}

const PopupContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  return (
    <PopupDispatchContext.Provider value={dispatch}>
      <PopupStateContext.Provider value={state}>
        {children}
      </PopupStateContext.Provider>
    </PopupDispatchContext.Provider>
  )
}

export default PopupContextProvider

export function useToggleDemo() {
  const dispatch = useContext(PopupDispatchContext)
  return useCallback(() => {
    dispatch({
      type: ACTIONS.DEMO_TOGGLE_POPUP,
    })
  }, [dispatch])
}

export function usePartnerActions() {
  const dispatch = useContext(PopupDispatchContext)
  const { partnerForm } = useContext(PopupStateContext)

  return {
    handleEmailInput: useCallback(
      event =>
        dispatch({
          type: ACTIONS.PARTNER_FORM_INPUT,
          payload: {
            email: event.target.value,
          },
        }),
      [dispatch]
    ),
    openPartnerForm: useCallback(
      event => {
        event && event.preventDefault && event.preventDefault()

        dispatch({
          type: ACTIONS.PARTNER_TOGGLE_POPUP,
        })
      },
      [dispatch]
    ),
    email: partnerForm.email,
  }
}
