import React, { useContext, useMemo } from "react"

import Modal from "../modal"
import { CloseBold } from "../icons"
import {
  PopupStateContext,
  PopupDispatchContext,
  ACTIONS,
} from "../../context/popupContext"

const Form = React.lazy(() => import("./form"))
const ThankYou = React.lazy(() => import("./thankYou"))

const PartnerPopupModal = () => {
  const dispatch = useContext(PopupDispatchContext)
  const { partnerForm } = useContext(PopupStateContext)
  const { toggle, formInputHandler, formSubmitHandler, resetForm } = useMemo(
    () => ({
      resetForm() {
        dispatch({ type: ACTIONS.PARTNER_RESET_FORM })
      },
      toggle() {
        dispatch({ type: ACTIONS.PARTNER_TOGGLE_POPUP })
      },
      formInputHandler(event) {
        dispatch({
          type: ACTIONS.PARTNER_FORM_INPUT,
          payload: {
            [event.target.id]: event.target.value,
          },
        })
      },
      formSubmitHandler(event) {
        event.preventDefault()
        dispatch({
          type: ACTIONS.PARTNER_SUBMIT_FORM,
        })
      },
    }),
    [dispatch]
  )

  let content = null

  switch (partnerForm.page) {
    case "form":
      content = (
        <Form
          formData={partnerForm}
          formSubmitHandler={formSubmitHandler}
          formInputHandler={formInputHandler}
        />
      )
      break

    case "thank_you":
      content = <ThankYou />
      break

    default:
      content = null
  }

  return partnerForm.open ? (
    <Modal>
      <CloseBold
        onClick={partnerForm.page === "thank_you" ? resetForm : toggle}
        className="z-50 cursor-pointer bg-white rounded-full absolute top-1/1 right-1/50 lg:-right-5 lg:-top-5"
      />
      <React.Suspense fallback={<span />}>
        <div className="h-full w-full flex flex-col overflow-auto">
          <div className="px-6 py-4 flex flex-col flex-grow lg:flex-row bg-bgGrey lg:rounded-lg flex justify-center items-center">
            {content}
          </div>
        </div>
      </React.Suspense>
    </Modal>
  ) : null
}

export default PartnerPopupModal
