import React, { useContext, useMemo } from "react"

import Modal from "../modal"
import { CloseBold } from "../icons"
import {
  PopupStateContext,
  PopupDispatchContext,
  ACTIONS,
} from "../../context/popupContext"
import * as styles from "./index.module.scss"

const Calendar = React.lazy(() => import("./calendar"))
const Video = React.lazy(() => import("./video"))
const Form = React.lazy(() => import("./form"))
const ThankYou = React.lazy(() => import("./thankYou"))

const DemoPopupModal = () => {
  const dispatch = useContext(PopupDispatchContext)
  const { demoForm } = useContext(PopupStateContext)
  const {
    toggle,
    formInputHandler,
    formSubmitHandler,
    showForm,
    eventScheduledHandler,
    resetForm,
  } = useMemo(
    () => ({
      resetForm() {
        dispatch({ type: ACTIONS.DEMO_RESET_FORM })
      },
      toggle() {
        dispatch({ type: ACTIONS.DEMO_TOGGLE_POPUP })
      },
      formInputHandler(event) {
        dispatch({
          type: ACTIONS.DEMO_FORM_INPUT,
          payload: {
            [event.target.id]: event.target.value,
          },
        })
      },
      showForm() {
        dispatch({
          type: ACTIONS.DEMO_SHOW_FORM,
        })
      },
      formSubmitHandler(event) {
        event.preventDefault()
        dispatch({
          type: ACTIONS.DEMO_SUBMIT_FORM,
        })
      },
      eventScheduledHandler(e) {
        if (
          e &&
          e.data &&
          e.data.event &&
          e.data.event === "calendly.event_scheduled"
        ) {
          dispatch({
            type: ACTIONS.DEMO_SUBMIT_EVENT,
          })
        } else {
          console.log(e.data)
        }
      },
    }),
    [dispatch]
  )

  let content = null

  switch (demoForm.page) {
    case "video":
      content = <Video showForm={showForm} />
      break

    case "form":
      content = (
        <Form
          formData={demoForm}
          formSubmitHandler={formSubmitHandler}
          formInputHandler={formInputHandler}
        />
      )
      break

    case "calendar":
      content = (
        <Calendar
          eventScheduledHandler={eventScheduledHandler}
          formData={demoForm}
        />
      )
      break

    case "thank_you":
      content = <ThankYou />
      break

    default:
      content = null
  }

  return demoForm.open ? (
    <Modal>
      <CloseBold
        onClick={demoForm.page === "thank_you" ? resetForm : toggle}
        className={styles.closeButton}
      />
      <React.Suspense fallback={<span />}>
        <div className="h-full w-full flex flex-col overflow-auto">
          <div className="px-6 py-4 flex flex-col flex-grow lg:flex-row bg-bgGrey lg:rounded-lg flex justify-center items-center">
            {content}
          </div>
        </div>
      </React.Suspense>
    </Modal>
  ) : null
}

export default DemoPopupModal
